import React from 'react'
import { createMuiTheme } from "@material-ui/core"
import { ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary:{
            light: "#f48546",
            main: "#f26718",
            dark: "#a94810"
        },
        secondary:{
            main: "#434789"
        }
    }
})

const ThemeWrapper = ({children}) => {
    return (<ThemeProvider theme={theme}>{children}</ThemeProvider>)
}

export default ThemeWrapper