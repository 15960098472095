import React, { Dispatch, SetStateAction, useMemo } from 'react'


export interface UserData{
    loggedIn: boolean,
    userId?: number,
    userName?: string,
    Email?: string,
    accessToken?: string,
    refreshToken?: string
}

interface UserDataProviderValue {
    user: UserData,
    setUser: React.Dispatch<React.SetStateAction<UserData>>
}


export const UserContext = React.createContext<UserDataProviderValue>(null);

export const UserProvider = ({children}) => {
    const [user, setUser] = React.useState<UserData>({loggedIn: false});
    const providerValue = useMemo(() => ({ user, setUser}), [user, setUser])

    return(
        <UserContext.Provider value={
            providerValue
        }>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => React.useContext(UserContext)