import React from 'react'
import ThemeWrapper from './src/components/theme'
import {UserProvider} from './src/context/UserContext'

export const wrapPageElement = ({element, props}) => (
    
    <ThemeWrapper {...props}>
        <UserProvider>
        {element}
        </UserProvider>
    </ThemeWrapper>
)